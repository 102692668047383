import * as React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

export const Head = () => (
  <>
    <title>Elektroliza w Olsztynie | Gabinet Elżbieta</title>
    <link rel="canonical" href="https://gabinetelzbieta.pl/oferta/elektroliza/" />
  </>
);

const Main = styled.main`
  font-family: 'Lato', sans-serif;
`;

const Slider = styled.section`
  .gatsby-image-wrapper-constrained {
    width: 100%;
  }
`;

const Elektroliza = ({ data }) => (
  <>
    <Slider>
      <GatsbyImage
        image={getImage(data.hero_4)}
        alt="Gabinet Elżbieta"
        loading="eager"
      />
    </Slider>

    <Main>
      <div className="row">
        <h1>Elektroliza</h1>

        <p>Jest to najskuteczniejsza i jedyna na świecie metoda, która na trwale usuwa mieszki włosowe. Są one odpowiedzialne za odrastanie włosa. Technika ta pozwala na pozbycie się pojedynczego włosa lub całej brody. Zabieg jest bardzo precyzyjny. Cebulki wyniszczane są pojedyczno.</p>

        <p>Tą metodą mogą być niszczone włosy pozbawione melaniny. To są włosy: blond, białe, siwe. Te włosy nie nadają sie do depilacji laserowej.</p>

        <h2>Przebieg zabiegu</h2>

        <p>Po dezynfekcji skóry wprowadzamy bardzo cienką igłę do cebulki włosa i zastosowujemy prąd. W odczuciu prąd jest bardzo delikatny. Następnie pensetą wyjmujemy zniszczony włos.</p>

        <h2>Przed zabiegiem</h2>

        <ul>
          <li>włosy zapuszczamy do 0,5 cm</li>
          <li>
            <strong>ważne:</strong>
            {' '}
            przed zabiegiem należy zrobić piling, aby usunąć zrogowaciały naskórek
          </li>
          <li>przed zabiegiem wmasowujemy kremy, które nawilżają i odżywiają skórę</li>
        </ul>

        <h2>Po zabiegu</h2>

        <ul>
          <li>zabieg powtarzamy co 4-6 tygodni</li>
          <li>po zabiegu może być obrzęk i zaczerwienie</li>
          <li>po zabiegu nie można się opalać</li>
          <li>należy stosować kremy z filtrem UV oraz kremy łagodzące i przyspieszające gojenie</li>
        </ul>

        <h2>Przeciwwskazania</h2>

        <ul>
          <li>ciąża</li>
          <li>karmienie piersią</li>
          <li>gorączka</li>
          <li>stany zapalne na skórze</li>
          <li>infekcja</li>
          <li>rozrusznik serca</li>
        </ul>

        <h2>Cena</h2>

        <p>Od 0,90 zł za impuls.</p>
      </div>
    </Main>
  </>
);

export const query = graphql`
  query {
    hero_4: file(relativePath: {regex: "/hero\/4.jpg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 2000
        )
      }
    }
  }
`;

export default Elektroliza;
